import { contentMargin } from '~/styles/sizes'
import styled from 'styled-components'

export const Arrow = styled.div`
    ${({ theme }) => `
        position: absolute;
        right: 0;
        bottom: 0;

        h1 {
            padding: ${theme.spacing(contentMargin)};    
            
            pointer-events: all;
            cursor: pointer;
        }
    `}
`

import { SliderContainer, SliderItem } from './CategoryColumnSlider.style'

import CategoryColumn from '~/components/CategoryColumn'
import { OverviewColumn } from '~/types'
import React from 'react'
import SwipeIcon from '~/components/SwipeIcon'

type ViewProps = {
    columns: OverviewColumn[]
}

export default ({ columns }: ViewProps) => {
    return (
        <SliderContainer>
            {columns.map((column, index) => (
                <SliderItem key={index}>
                    <CategoryColumn categoryColumn={column} />
                </SliderItem>
            ))}
            <SwipeIcon />
        </SliderContainer>
    )
}

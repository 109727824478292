import { Column, ColumnContainer, Title } from './CategoryColumn.style'
import React, { useEffect, useRef } from 'react'

import ColorTypography from '~/components/ColorTypography'
import Div100vh from 'react-div-100vh'
import { Grid } from '@material-ui/core'
import Mobile from '~/components/Mobile'
import { OverviewColumn } from '~/types'
import ProjectPreview from '~/components/ProjectPreview'

type ViewProps = {
    categoryColumn: OverviewColumn
}

export default ({ categoryColumn }: ViewProps) => {
    const columnRef = useRef<HTMLDivElement>(null)
    const { projects } = categoryColumn

    function handleScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
        if (!columnRef.current) {
            return
        } else if (
            columnRef.current.scrollHeight - columnRef.current.scrollTop ===
            columnRef.current.clientHeight
        ) {
            columnRef.current.scrollTop = 1
        } else if (columnRef.current.scrollTop === 0) {
            columnRef.current.scrollTop =
                columnRef.current.scrollHeight - columnRef.current.clientHeight - 1
        }
    }

    useEffect(() => {
        if (columnRef.current) {
            columnRef.current.scrollTop = 1
        }
    }, [columnRef.current])

    return (
        <>
            <Grid item xs={12} md={4}>
                <ColumnContainer>
                    <Column onScroll={handleScroll} ref={columnRef}>
                        <Div100vh>
                            {projects.concat(projects.slice(0, 2)).map((project, index) => (
                                <ProjectPreview
                                    key={`${index}_${project.url.current}`}
                                    project={project}
                                />
                            ))}
                        </Div100vh>
                    </Column>
                    <Mobile>
                        <Title>                    
                            <ColorTypography variant="h1">{categoryColumn.title.toUpperCase()}</ColorTypography>
                        </Title>
                    </Mobile>
                </ColumnContainer>
            </Grid>
        </>
    )
}

import { Grid, withWidth } from '@material-ui/core'
import React, { useState } from 'react'

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import CategoryColumn from '~/components/CategoryColumn'
import CategoryColumnSlider from '~/components/CategoryColumnSlider'
import Head from '~/components/Head'
import { OverviewColumn } from '~/types'
import Tooltip from '~/components/Tooltip'
import { graphql } from 'gatsby'

export const query = graphql`
    {
        sanityOverview {
            columns {
                title
                projects {
                    title
                    url {
                        current
                    }
                    teaserVideoFile {
                        asset {
                            url
                        }
                    }
                    teaserVideoPlaceholder {
                        asset {
                            url
                        }
                    }
                }
            }
        }
    }
`

type ViewProps = {
    data: {
        sanityOverview: {
            columns: OverviewColumn[]
        }
    }
    width: Breakpoint
}

const IndexPage = withWidth()(({ data, width }: ViewProps) => {
    const isMobile = ['xs', 'sm'].includes(width)

    const overviewColumns = [
        <CategoryColumn key={0} categoryColumn={data.sanityOverview.columns[0]} />,
        <CategoryColumn key={1} categoryColumn={data.sanityOverview.columns[1]} />,
        <CategoryColumn key={2} categoryColumn={data.sanityOverview.columns[2]} />
    ]

    return (
        <>
            <Head title="Home" />
            {isMobile ? (
                <CategoryColumnSlider columns={data.sanityOverview.columns} />
            ) : (
                <Grid container>{overviewColumns}</Grid>
            )}
            <Tooltip />
        </>
    )
})

export default IndexPage

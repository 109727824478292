import styled from 'styled-components'

export const SliderContainer = styled.div`
    overflow: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
`

export const SliderItem = styled.div`
    display: inline-block;
    width: 100%;
    scroll-snap-align: start;
`

import { contentMargin } from '~/styles/sizes'
import styled from 'styled-components'

export const ColumnContainer = styled.div`
    position: relative;
`

export const Column = styled.div`
    position: relative;
    width: 100%;
    overflow-y: scroll;
`

export const Title = styled.div`
    position: absolute;
    z-index: 1000;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: ${({ theme }) => theme.spacing(contentMargin)};
    pointer-events: none;
`
import { Arrow } from './SwipeIcon.style'
import ColorTypography from '~/components/ColorTypography'
import React from 'react'
import { Unicode } from '~/constants/unicode'

export default () => {
    return (
        <Arrow>
            <ColorTypography variant="h1">{Unicode.SWIPE}</ColorTypography>
        </Arrow>
    )
}
